<template>
  <v-card
    class="rounded-xl"
    :elevation="8"
    :color="abgesagt ? 'rgba(255,255,255,0.5)' : template.colors.blocks"
    :style="`color: ${template.colors.block_text}; height: 100%;`"
    link
    :loading="loading"
    @click="loading = true"
    :to="`/wettkampf/${veranstaltung.id}/disziplinen/${id}`"
  >
    <v-row class="px-2">
      <v-col cols="2" align-self="center">
        <v-avatar class="my-auto">
          <v-icon
            :class="abgesagt ? 'grey darken-3 error--text' : ''"
            :style="`background-color: ${template.colors.secondary}; color: ${template.colors.inline_secondary_text};`"
          >
            mdi-podium-gold
          </v-icon>
        </v-avatar>
      </v-col>
      <v-col cols="8" align-self="center">
        <span :class="abgesagt ? 'text-decoration-line-through' : undefined">
          <small v-if="beginndatum">{{ parseDate(beginndatum) }}</small>
          <span v-if="beginndatum">&nbsp;|&nbsp;</span>
          <b>{{ beginnuhrzeit }} Uhr</b>
        </span>
        <br />
        <span>
          <b>
            {{ name }}
          </b>
          <br />
          <small>{{ sportstaette }}</small>
        </span>
      </v-col>
      <v-col cols="2" align-self="center" class="text-right">
        <v-icon large :color="template.colors.block_text">
          mdi-chevron-right
        </v-icon>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'veranstaltungscard',
  data() {
    return {
      loading: false,
    }
  },
  props: {
    name: String,
    id: String,
    sportstaette: String,
    beginnuhrzeit: String,
    beginndatum: String,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      var datum_obj = new Date(date)
      var weekdays = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
      var weekday = datum_obj.getDay()
      return (
        weekdays[weekday] + ', ' + datum[2] + '.' + datum[1] + '.' + datum[0]
      )
    },
  },
}
</script>
