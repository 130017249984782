<template>
  <div>
    <v-row class="pt-5" justify="center">
      <v-col cols="12" lg="11">
        <v-btn :to="`/wettkampf/${veranstaltung.id}`" exact text rounded>
          <v-icon>mdi-chevron-left</v-icon>
          Zurück zum Wettkampf
        </v-btn>
      </v-col>
      <v-col cols="12" lg="11">
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl"
        >
          <v-row
            :justify="veranstaltung.data.disziplinen ? 'center' : undefined"
          >
            <v-col
              cols="12"
              md="6"
              align-self="center"
              class="text-center pa-4"
            >
              <h5 class="text-uppercase font-weight-bold">
                Wettkampfkontrolle
              </h5>
              <h2>
                <v-icon class="mr-2" large :color="template.colors.block_text">
                  mdi-stadium
                </v-icon>
                {{ veranstaltung.data.name }}
              </h2>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
              <v-card
                :color="
                  maxzuschauer_erreicht()
                    ? '#F44336'
                    : template.modules.control.box_secondary
                "
                :style="
                  maxzuschauer_erreicht()
                    ? `color: #FFFFFF;`
                    : `color: ${template.modules.control.box_secondary_text};`
                "
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'rounded-tr-xl rounded-bl-xl'
                    : 'my-2'
                "
              >
                <v-row justify="center">
                  <v-col cols="12" class="pt-4 px-4 text-center">
                    <v-row justify="center" class="py-0">
                      <v-col cols="auto" align-self="center">
                        <h4 class="text-center text-uppercase">
                          Anwesende Personen
                        </h4>
                      </v-col>
                      <v-col cols="auto" align-self="center">
                        <v-btn
                          icon
                          :color="
                            maxzuschauer_erreicht()
                              ? '#F44336'
                              : template.modules.control.box_secondary_text
                          "
                          :loading="aktualisiere_personenzahl"
                          @click="check_current_time_once()"
                        >
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                    <h1 class="text-center" v-if="!aktualisiere_personenzahl">
                      {{ get_derzeit_anwesende_personen() }}
                      <small class="font-weight-light">
                        /{{
                          sportstaette.maxzuschauer
                            ? sportstaette.maxzuschauer
                            : this.user.data.maxzuschauer
                        }}
                      </small>
                    </h1>
                    <v-progress-circular
                      :width="4"
                      :color="
                        maxzuschauer_erreicht()
                          ? '#F44336'
                          : template.colors.inline_secondary_text
                      "
                      indeterminate
                      v-if="aktualisiere_personenzahl"
                      class="my-2"
                    ></v-progress-circular>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <cardbutton
                      :title="'Personen verwalten'"
                      :icon="'mdi-clipboard-list'"
                      :textcolor="template.modules.control.buttons_text"
                      :bgcolor="template.modules.control.buttons"
                      upper
                      :disabled="anwesende_personen.length == 0"
                      :addclass="
                        $vuetify.breakpoint.mdAndUp
                          ? 'rounded-bl-xl mb-0'
                          : 'mb-0'
                      "
                      @click="personenverwalten = true"
                    />
                    <personenverwaltung
                      v-if="personenverwalten"
                      v-on:closed="personenverwalten = false"
                      :personen="anwesende_personen"
                      :veranstaltungen="veranstaltungen"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-center pa-4"
              v-if="veranstaltung.data.disziplinen"
            >
              <h3>
                HEUTIGE
                {{ template.var.verband == 'DEU' ? 'GRUPPEN' : 'DISZIPLINEN' }}
              </h3>
              <small>
                Am
                <b>{{ this.timer.datum_format }}</b>
                um
                <b>{{ this.timer.uhrzeit_format_sec }} Uhr</b>
              </small>
              <v-row justify="center" v-if="aktualisiere_veranstaltungen">
                <v-col cols="12" class="text-center">
                  <v-progress-circular
                    :width="5"
                    :color="template.colors.block_text"
                    indeterminate
                    class="my-2"
                  ></v-progress-circular>
                </v-col>
              </v-row>
              <v-row
                justify="center"
                class="pt-0"
                v-if="!aktualisiere_veranstaltungen"
              >
                <v-col
                  cols="12"
                  class="pt-0"
                  v-for="d in disziplinen"
                  :key="d.id"
                >
                  <card-disziplin
                    :id="d.id"
                    :name="d.name"
                    :sportstaette="d.sportstaette"
                    :beginnuhrzeit="d.beginn.uhrzeit"
                    :beginndatum="d.beginn.datum"
                  />
                </v-col>
              </v-row>
              <p class="mt-4" v-if="disziplinen.length == 0">
                Derzeit finde keine
                {{ template.var.verband == 'DEU' ? 'Gruppen' : 'Disziplinen' }}
                statt.
              </p>
            </v-col>
            <v-col cols="12" md="6" class="text-center pb-0">
              <v-card
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'rounded-xl pb-0' : 'my-2'
                "
              >
                <v-row justify="center">
                  <v-col
                    cols="12"
                    :class="
                      !veranstaltung.data.disziplinen || disziplinen.length > 0
                        ? 'pb-0'
                        : undefined
                    "
                  >
                    <v-card
                      :color="template.colors.primary"
                      :light="!template.isDark(template.colors.primary)"
                      :dark="template.isDark(template.colors.primary)"
                      elevation="0"
                      class="rounded-xl"
                      @click="
                        newzuschauer = true
                        zs.type = 'person'
                      "
                    >
                      <v-icon large class="mr-2">mdi-account-plus</v-icon>
                      Neue Anwesenheit erfassen
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="
                      !veranstaltung.data.disziplinen || disziplinen.length > 0
                    "
                  >
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col
                    cols="12"
                    class="pt-0"
                    v-if="
                      veranstaltung.data.disziplinen && disziplinen.length > 0
                    "
                  >
                    <v-card
                      :color="template.colors.primary"
                      :light="!template.isDark(template.colors.primary)"
                      :dark="template.isDark(template.colors.primary)"
                      elevation="0"
                      class="rounded-xl"
                      @click="
                        newzuschauer = true
                        zs.type = 'athlet'
                      "
                    >
                      <v-icon large class="mr-2">mdi-shield-account</v-icon>
                      {{
                        template.var.verband == 'DEU'
                          ? 'Gruppenteilnahme'
                          : 'Disziplinteilnahme'
                      }}
                      erfassen
                    </v-card>
                  </v-col>
                  <v-col cols="12" v-if="!veranstaltung.data.disziplinen">
                    <v-card
                      :color="template.colors.primary"
                      :light="!template.isDark(template.colors.primary)"
                      :dark="template.isDark(template.colors.primary)"
                      elevation="0"
                      class="rounded-xl"
                      @click="
                        newzuschauer = true
                        zs.type = 'athlet'
                      "
                    >
                      <v-icon large class="mr-2">mdi-magnify</v-icon>
                      Anmeldung suchen
                    </v-card>
                  </v-col>
                  <v-col cols="12" v-if="false">
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col
                    cols="12"
                    class="pt-0"
                    v-if="!veranstaltung.data.disziplinen && false"
                  >
                    <v-card
                      :color="template.colors.primary"
                      :light="!template.isDark(template.colors.primary)"
                      :dark="template.isDark(template.colors.primary)"
                      elevation="0"
                      @click="
                        newzuschauer = true
                        zs.type = 'athlet'
                      "
                    >
                      <v-icon large class="mr-2">mdi-qrcode</v-icon>
                      Anmeldung scannen
                    </v-card>
                  </v-col>
                  <v-col cols="12" v-if="false">
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col cols="12" class="pt-0" v-if="false">
                    <v-card
                      :color="template.colors.primary"
                      :light="!template.isDark(template.colors.primary)"
                      :dark="template.isDark(template.colors.primary)"
                      elevation="0"
                      @click="
                        newzuschauer = true
                        zs.type = 'qrcode'
                      "
                    >
                      <v-icon large class="mr-2">mdi-qrcode</v-icon>
                      CheckIn-Karte scannen
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      :value="newzuschauer"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :persistent="$vuetify.breakpoint.lgAndUp"
      transition="dialog-bottom-transition"
      :max-width="$vuetify.breakpoint.lgAndUp ? '800' : undefined"
      dark
      style="padding-top: env(safe-area-inset-top);"
      :retain-focus="false"
      :content-class="$vuetify.breakpoint.mdAndUp ? 'rounded-xl' : undefined"
      :overlay-color="template.colors.secondary"
      overlay-opacity="0.9"
    >
      <v-card style="width: 100vw; overflow-x: hidden;">
        <v-toolbar
          fixed
          :color="template.colors.primary"
          :style="`color: ${template.colors.inline_primary_text};`"
          style="
            padding-top: env(safe-area-inset-top);
            height: 80px + env(safe-area-inset-top);
          "
        >
          <v-toolbar-title v-if="zs.type == 'person'">
            Neue Anwesenheit erfassen
          </v-toolbar-title>
          <v-toolbar-title v-if="zs.type == 'qrcode'">
            CheckIn-Karte scannen
          </v-toolbar-title>
          <v-toolbar-title v-if="zs.type == 'verein'">
            Vereins-Person erfassen
          </v-toolbar-title>
          <v-toolbar-title v-if="zs.type == 'athlet'">
            Anmeldung suchen
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closing()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row
          justify="center"
          class="pa-4"
          v-if="
            !zs.disziplin &&
            zs.type == 'athlet' &&
            !disziplinselection &&
            veranstaltung.data.disziplinen
          "
        >
          <v-col cols="12" class="text-center">
            <h3>
              Bitte wähle die
              {{ template.var.verband == 'DEU' ? 'Gruppe' : 'Disziplin' }}, für
              die die Person erfasst werden soll:
            </h3>
          </v-col>
          <v-col cols="12" md="5" v-for="d in disziplinen" :key="d.id">
            <v-card
              class="rounded-xl"
              :elevation="8"
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text}; height: 100%;`"
              link
              :loading="disziplinloading == d.id"
              @click="select_disziplin(d)"
            >
              <v-row class="px-2">
                <v-col cols="2" align-self="center">
                  <v-avatar class="my-auto">
                    <v-icon class="grey lighten-1">
                      mdi-podium-gold
                    </v-icon>
                  </v-avatar>
                </v-col>
                <v-col cols="8" align-self="center">
                  <span>
                    <b>{{ d.name }}</b>
                  </span>
                  <br />
                  <small>
                    <b>{{ d.beginn.uhrzeit }} bis {{ d.ende.uhrzeit }} Uhr</b>
                    | {{ d.sportstaette }}
                  </small>
                </v-col>
                <v-col cols="2" align-self="center">
                  <v-icon large :color="template.colors.block_text">
                    mdi-chevron-right
                  </v-icon>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          v-if="zs.type == 'person'"
        >
          <v-row justify="center" class="mt-4" v-if="zs.type == 'person'">
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                label="Vorname"
                :rules="[rules.required, rules.name]"
                v-model="zs.person.vorname"
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                label="Nachname"
                :rules="[rules.required, rules.name]"
                v-model="zs.person.name"
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                label="Strasse + Nr."
                :rules="[rules.required, rules.street, rules.streetnr]"
                v-model="zs.person.adresse"
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="py-0">
              <v-text-field
                filled
                label="Postleitzahl"
                :rules="[rules.required, rules.plz]"
                v-model="zs.person.plz"
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                filled
                label="Ort"
                :rules="[rules.required, rules.name]"
                v-model="zs.person.ort"
                rounded
              ></v-text-field>
            </v-col>
            <v-col
              cols="11"
              class="py-0"
              v-if="
                template.var.verband == 'DEU' ||
                veranstaltung.data.nation_required
              "
            >
              <v-text-field
                filled
                label="Nation"
                :rules="[rules.required]"
                v-model="zs.person.nation"
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                label="Telefon"
                :rules="[rules.required, rules.telefon]"
                v-model="zs.person.telefon"
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                label="E-Mail"
                :rules="[rules.required, rules.email]"
                v-model="zs.person.email"
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-select
                filled
                label="Funktion"
                :items="aktivitaeten"
                :rules="[rules.required]"
                v-model="zs.person.aktivitaet"
                rounded
              ></v-select>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-select
                filled
                rounded
                :items="zeiten"
                label="Dauer der Anwesenheit"
                item-text="label"
                item-value="time"
                v-model="zeitwahl"
              ></v-select>
            </v-col>
            <v-col cols="11" class="py-0">
              <ggswitches
                :geimpft="false"
                :getestet="false"
                :genesen="false"
                :datum="false"
                @valid="validate_ggg($event)"
                @change="get_ggg_data($event)"
              ></ggswitches>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-btn
                class="my-4"
                block
                large
                rounded
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                @click="zuschauer_erfassen()"
                :disabled="
                  !zs.person.vorname ||
                  !zs.person.name ||
                  !zs.person.adresse ||
                  !zs.person.plz ||
                  !zs.person.ort ||
                  (template.var.verband == 'DEU' ||
                  veranstaltung.data.nation_required
                    ? !zs.person.nation
                    : false) ||
                  !zs.person.telefon ||
                  !zs.person.email ||
                  !zs.person.aktivitaet ||
                  !zeitwahl ||
                  testpflicht
                "
                :loading="creating"
              >
                <v-icon class="mr-2">mdi-plus</v-icon>
                Hinzufügen
              </v-btn>
              <v-btn
                class="my-4"
                block
                large
                outlined
                rounded
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                @click="newzuschauer = false"
              >
                Abbrechen
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-4" v-if="zs.type == 'qrcode'">
            <v-col
              cols="10"
              lg="8"
              class="px-2 text-center"
              v-if="!qrcodedata && camera_loading"
            >
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <h3 class="mt-3">Kamera wird geladen</h3>
            </v-col>
            <v-col cols="12" lg="8" class="px-2" v-if="!qrcodedata">
              <qrcode-stream @detect="onDetect" @init="onInit" />
            </v-col>
            <v-col cols="10" lg="8" class="px-2" v-if="qrcodedata">
              <v-text-field
                filled
                label="Vorname"
                v-model="zs.vorname"
              ></v-text-field>
              <v-text-field
                filled
                label="Name"
                v-model="zs.name"
              ></v-text-field>
              <v-text-field
                filled
                label="Straße + Nr"
                v-model="zs.adresse"
              ></v-text-field>
              <v-text-field filled label="PLZ" v-model="zs.plz"></v-text-field>
              <v-text-field filled label="Ort" v-model="zs.ort"></v-text-field>
              <v-text-field
                filled
                label="Telefon"
                v-model="zs.telefon"
              ></v-text-field>
              <v-select
                filled
                :items="zeiten"
                label="Dauer der Anwesenheit"
                item-text="label"
                item-value="time"
                v-model="zeitwahl"
                v-if="zs.veranstaltung.id == 'ANDERE'"
              ></v-select>
              <v-btn
                class="my-4"
                block
                large
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                @click="zuschauer_erfassen()"
                :loading="creating"
              >
                <v-icon class="mr-2">mdi-plus</v-icon>
                Hinzufügen
              </v-btn>
              <v-btn
                class="my-4"
                block
                large
                outlined
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                @click="newzuschauer = false"
              >
                Abbrechen
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row
          justify="center"
          v-if="
            zs.disziplin &&
            zs.type == 'athlet' &&
            disziplinselection &&
            veranstaltung.data.disziplinen
          "
        >
          <v-col cols="10" class="pt-0">
            <v-row justify="center" class="pt-0">
              <v-col cols="7" md="6">
                <v-text-field
                  solo
                  rounded
                  light
                  prepend-inner-icon="mdi-magnify"
                  label="Suche"
                  v-model="db_search"
                  @input="search_athletes()"
                ></v-text-field>
              </v-col>
              <v-col cols="5" md="4">
                <v-select
                  filled
                  rounded
                  label="Sortierung"
                  :items="sort_auswahl"
                  v-model="sortierung"
                ></v-select>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn
                  x-large
                  class="rounded-xl"
                  :color="
                    selected_athletes.length == 0
                      ? template.colors.blocks
                      : template.colors.primary
                  "
                  :outlined="selected_athletes.length == 0"
                  block
                  :disabled="selected_athletes.length == 0"
                  @click="add_athletes_disziplin()"
                >
                  {{
                    selected_athletes.length == 0
                      ? 'KEINE PERSON AUSGEWÄHLT'
                      : selected_athletes.length > 1
                      ? selected_athletes.length + ' PERSONEN ERFASSEN'
                      : selected_athletes.length + ' PERSON ERFASSEN'
                  }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-for="(member, index) in athletesresults"
                :key="member.id"
                class="py-0"
              >
                <cardbutton
                  v-if="
                    member.person && member.person.vorname && member.person.name
                  "
                  :title="
                    member.startnummer +
                    ' - ' +
                    member.person.vorname +
                    ' ' +
                    member.person.name
                  "
                  :icon="member.selected ? 'mdi-check-bold' : 'mdi-account'"
                  :textcolor="template.colors.block_text"
                  :bgcolor="
                    member.selected ? 'success' : template.colors.blocks
                  "
                  upper
                  :disabled="gg_regel && (!member.geimpft || !member.genesen)"
                  @click="select_athlete(index)"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          v-if="zs.type == 'athlet' && !veranstaltung.data.disziplinen"
        >
          <v-col
            cols="10"
            align-self="center"
            class="my-5"
            v-if="athletes.length == 0"
          >
            <h3>Es sind keine Anmeldungen für diesen Wettkampf vorhanden.</h3>
          </v-col>
          <v-col cols="7" md="6">
            <v-text-field
              solo
              rounded
              light
              prepend-inner-icon="mdi-magnify"
              label="Suche"
              v-model="db_search"
              @input="search_athletes()"
            ></v-text-field>
          </v-col>
          <v-col cols="5" md="4">
            <v-select
              filled
              rounded
              label="Sortierung"
              :items="sort_auswahl"
              v-model="sortierung"
            ></v-select>
          </v-col>
          <v-col cols="10" class="pt-0" v-if="athletes_results.length > 0">
            <v-row justify="center" class="pt-0">
              <v-col cols="12" class="text-center">
                <v-btn
                  x-large
                  class="rounded-xl"
                  :color="
                    selected_athletes.length == 0
                      ? template.colors.blocks
                      : template.colors.primary
                  "
                  :outlined="selected_athletes.length == 0"
                  block
                  :disabled="selected_athletes.length == 0"
                  @click="add_athletes()"
                >
                  {{
                    selected_athletes.length == 0
                      ? 'KEINE PERSON AUSGEWÄHLT'
                      : selected_athletes.length > 1
                      ? selected_athletes.length + ' PERSONEN ERFASSEN'
                      : selected_athletes.length + ' PERSON ERFASSEN'
                  }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-for="(member, index) in athletesresults"
                :key="member.id"
                class="py-0"
              >
                <v-card
                  :color="
                    member.selected
                      ? template.colors.success
                      : template.colors.blocks
                  "
                  :light="
                    member.selected
                      ? !template.isDark(template.colors.success)
                      : !template.isDark(template.colors.blocks)
                  "
                  :dark="
                    member.selected
                      ? template.isDark(template.colors.success)
                      : template.isDark(template.colors.blocks)
                  "
                  :disabled="
                    athleten_ids.includes(member.id) ||
                    (gg_regel && !member.geimpft && !member.genesen)
                  "
                  class="rounded-xl px-3 ma-2"
                  @click="select_athlete(index)"
                  link
                >
                  <v-row justify="center">
                    <v-col cols="auto" align-self="center">
                      <v-avatar
                        :color="
                          member.selected
                            ? template.colors.success_text
                            : template.colors.block_text
                        "
                      >
                        <v-icon
                          v-if="!member.startnummer || member.selected"
                          :color="
                            member.selected
                              ? template.colors.success
                              : template.colors.blocks
                          "
                        >
                          {{
                            member.selected ? 'mdi-check-bold' : 'mdi-account'
                          }}
                        </v-icon>
                        <span
                          v-if="member.startnummer && !member.selected"
                          :style="
                            'color: ' +
                            (member.selected
                              ? template.colors.success
                              : template.colors.blocks) +
                            ';'
                          "
                        >
                          {{ member.startnummer }}
                        </span>
                      </v-avatar>
                    </v-col>
                    <v-col align-self="center">
                      <h3>
                        {{ member.person.vorname + ' ' + member.person.name }}
                      </h3>
                      <h5 v-if="athleten_ids.includes(member.id)">
                        BEREITS ANWESEND
                      </h5>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      :value="duplicate_zuschauer.active"
      persistent
      transition="dialog-bottom-transition"
      :max-width="$vuetify.breakpoint.lgAndUp ? '600' : '500'"
      dark
      content-class="rounded-xl"
      :overlay-color="template.modules.control.dialog"
      overlay-opacity="0.9"
    >
      <v-card class="rounded-xl">
        <v-card-title>PERSON BEREITS ANWESEND</v-card-title>
        <v-card-text>
          Es ist bereits eine Person mit dem Namen
          {{ duplicate_zuschauer.vorname }}
          {{ duplicate_zuschauer.name }} anwesend. Möchten Sie die Anwesenheit
          trotzdem erfassen?
        </v-card-text>
        <v-card-actions>
          <v-btn text :color="template.colors.danger" @click="closing()">
            Abbrechen
          </v-btn>
          <v-btn
            text
            :color="template.colors.success"
            @click="
              accept_duplicate_zuschauer = true
              zuschauer_erfassen()
            "
          >
            Bestätigen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import cardbutton from '../../../../../elements/cards/card-button'
import cardveranstaltung from '../../../../../elements/cards/card-veranstaltung'
import personenverwaltung from './module/personen_verwalten'
import { mapGetters } from 'vuex'
import { QrcodeStream } from 'vue-qrcode-reader'
//import firebase from 'firebase'
import store from '../../../../../store'
import CardDisziplin from '../../../../../elements/cards/card-disziplin.vue'
import ggswitches from '../../../2G-Regel/switches.vue'

export default {
  name: 'Start',
  data() {
    return {
      zs: {
        veranstaltung: '',
        disziplin: '',
        type: '',
        person: {
          name: '',
          vorname: '',
          adresse: '',
          plz: '',
          ort: '',
          telefon: '',
          nation: '',
          email: '',
          aktivitaet: '',
        },
        save_person: false,
      },
      ggg: {
        getestet: false,
        geimpft: false,
        genesen: false,
      },
      ggg_valid: false,
      newveranstaltung: false,
      newzuschauer: false,
      personenverwalten: false,
      veranstaltungen: [],
      veranst_date: [],
      future_dates: [],
      athletes: [],
      athletes_results: [],
      db_search: '',
      past_dates: [],
      todays_dates: [],
      teams: [],
      teamselection: '',
      team_members: [],
      athleten_ids: [],
      selected_members: [],
      selected_athletes: [],
      anwesende_personen: [],
      aendere_sportstaette: false,
      zeiten: [
        {
          label: '15 Minuten',
          time: { hours: 0, minutes: 15 },
        },
        {
          label: '30 Minuten',
          time: { hours: 0, minutes: 30 },
        },
        {
          label: '1 Stunde',
          time: { hours: 1, minutes: 0 },
        },
        {
          label: '1.5 Stunden',
          time: { hours: 1, minutes: 30 },
        },
        {
          label: '2 Stunden',
          time: { hours: 2, minutes: 0 },
        },
        {
          label: '3 Stunden',
          time: { hours: 3, minutes: 0 },
        },
        {
          label: '4 Stunden',
          time: { hours: 4, minutes: 0 },
        },
        {
          label: '5 Stunden',
          time: { hours: 5, minutes: 0 },
        },
        {
          label: '6 Stunden',
          time: { hours: 6, minutes: 0 },
        },
        {
          label: '7 Stunden',
          time: { hours: 7, minutes: 0 },
        },
        {
          label: '8 Stunden',
          time: { hours: 8, minutes: 0 },
        },
        {
          label: '9 Stunden',
          time: { hours: 9, minutes: 0 },
        },
        {
          label: '10 Stunden',
          time: { hours: 10, minutes: 0 },
        },
        {
          label: '11 Stunden',
          time: { hours: 11, minutes: 0 },
        },
        {
          label: '12 Stunden',
          time: { hours: 12, minutes: 0 },
        },
      ],
      zeitwahl: {
        stunden: 0,
        minuten: 0,
      },
      aktivitaeten: [],
      newteam: false,
      sportstaette: '',
      qrcodedata: false,
      date: '',
      datum: '',
      current_time: '',
      tabs: 1,
      add_to_home: false,
      multidelete: false,
      disziplinloading: '',
      disziplinselection: '',
      notify: false,
      camera_loading: true,
      disziplinen: [],
      duplicate_zuschauer: {
        active: false,
        vorname: '',
        name: '',
      },
      accept_duplicate_zuschauer: false,
      aktualisiere_personenzahl: true,
      aktualisiere_veranstaltungen: true,
      rules: {
        required: (value) => !!value || 'Bitte gebe deine Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
      listeners: {
        anwesenheit: '',
        veranstaltungen: '',
      },
      sortierung: 'Name (aufsteigend)',
      sort_auswahl: [
        'Name (aufsteigend)',
        'Name (absteigend)',
        'Vorname (aufsteigend)',
        'Vorname (absteigend)',
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      timer: 'timer',
      veranstaltung: 'veranstaltung',
    }),
    testpflicht() {
      if (this.user.data) {
        if (this.user.data.hygienekonzept) {
          if (this.user.data.hygienekonzept.testpflicht) {
            if (this.selected_members.length > 0) {
              var alle_getestet = true
              this.selected_members.forEach((member) => {
                if (!member.genesen && !member.geimpft) {
                  alle_getestet = false
                }
              })
              if (!alle_getestet) {
                if (
                  !this.ggg.getestet &&
                  !this.ggg.genesen &&
                  !this.ggg.geimpft
                ) {
                  return true
                }
              }
            } else if (
              !this.ggg.getestet &&
              !this.ggg.genesen &&
              !this.ggg.geimpft
            ) {
              return true
            }
          }
        }
      }
      return false
    },
    testpflicht_required() {
      if (this.user.data && this.user.data.hygienekonzept) {
        if (this.user.data.hygienekonzept.testpflicht) {
          if (!this.user.data.hygienekonzept.gg_regel) {
            return true
          }
        }
      }
      return false
    },
    gg_regel() {
      if (this.user.data && this.user.data.hygienekonzept) {
        if (this.user.data.hygienekonzept.gg_regel) {
          return true
        }
      }
      return false
    },
    ggplus_regel() {
      if (this.user.data && this.user.data.hygienekonzept) {
        if (this.user.data.hygienekonzept.ggplus_regel) {
          return true
        }
      }
      return false
    },
    athletesresults() {
      var arr = this.athletes_results
      function compareNameASC(a, b) {
        if (a.person.name > b.person.name) {
          return 1
        } else {
          return -1
        }
      }
      function compareNameDESC(a, b) {
        if (a.person.name > b.person.name) {
          return -1
        } else {
          return 1
        }
      }
      function compareVornameASC(a, b) {
        if (a.person.vorname > b.person.vorname) {
          return 1
        } else {
          return -1
        }
      }
      function compareVornameDESC(a, b) {
        if (a.person.vorname > b.person.vorname) {
          return -1
        } else {
          return 1
        }
      }

      if (this.sortierung == 'Name (aufsteigend)') {
        arr.sort(compareNameASC)
      }
      if (this.sortierung == 'Name (absteigend)') {
        arr.sort(compareNameDESC)
      }
      if (this.sortierung == 'Vorname (aufsteigend)') {
        arr.sort(compareVornameASC)
      }
      if (this.sortierung == 'Vorname (absteigend)') {
        arr.sort(compareVornameDESC)
      }
      return arr
    },
  },
  mounted() {
    if (this.template.var.verband == 'DEU') {
      this.aktivitaeten = [
        'Athlete',
        'Coach',
        'Trainer',
        'Team Official',
        'OC',
        'Press/TV',
        'Chaperon',
      ]
    }
    if (this.$route.params.veranstaltung) {
      store.dispatch('setVeranstaltung', this.$route.params.veranstaltung)
    } else {
      store.dispatch('closeVeranstaltung')
    }
    if (this.$route.params.sportstaette) {
      this.sportstaette = this.user.data.sportstaetten[
        this.$route.params.sportstaette
      ]
    } else {
      this.sportstaette = this.user.data.sportstaetten[0]
    }
    if (
      this.user.data.rolle == 'Vorstand' ||
      this.user.data.rolle == 'Trainer'
    ) {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .collection('Disziplin')
        .where('beginn.datum', '==', this.timer.datum)
        .orderBy('beginn.uhrzeit', 'asc')
        .onSnapshot((snap) => {
          this.check_current_time_once()
        })
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('zutritt.datum', '==', this.timer.datum)
        .onSnapshot((querySnap) => {
          this.check_current_time_once()
        })

      this.user.data.aktivitaeten.forEach((aktivitaet) => {
        this.aktivitaeten.push(aktivitaet)
      })

      setTimeout(() => {
        this.check_current_time()
      }, 500)
    }
  },
  components: {
    cardbutton,
    QrcodeStream,
    personenverwaltung,
    CardDisziplin,
    ggswitches,
  },
  methods: {
    validate_ggg(daten) {
      this.ggg_valid = daten
    },
    get_ggg_data(daten) {
      if (daten) {
        this.ggg = {
          getestet: daten.ggg.getestet || false,
          geimpft: daten.ggg.geimpft || false,
          genesen: daten.ggg.genesen || false,
          geburtsdatum: daten.geburtsdatum || false,
        }
      }
    },
    sortieren(sort, asc) {
      function compareStartnrASC(a, b) {
        var aval = a.startnummer
        if (aval < 10) {
          aval = '0' + aval
        }
        var bval = b.startnummer
        if (bval < 10) {
          bval = '0' + bval
        }
        if (aval > bval) {
          return 1
        } else if (aval == bval) {
          if (a.person.name > b.person.name) {
            return 1
          } else {
            return -1
          }
        } else {
          return -1
        }
      }
      function compareStartnrDESC(a, b) {
        var aval = a.startnummer
        if (aval < 10) {
          aval = '0' + aval
        }
        var bval = b.startnummer
        if (bval < 10) {
          bval = '0' + bval
        }
        if (aval > bval) {
          return -1
        } else if (aval == bval) {
          if (a.person.name > b.person.name) {
            return 1
          } else {
            return 1
          }
        } else {
          return 1
        }
      }
      function compareNameASC(a, b) {
        if (a.person.name > b.person.name) {
          return 1
        } else {
          return -1
        }
      }
      function compareNameDESC(a, b) {
        if (a.person.name > b.person.name) {
          return -1
        } else {
          return 1
        }
      }
      function compareVornameASC(a, b) {
        if (a.person.vorname > b.person.vorname) {
          return 1
        } else {
          return -1
        }
      }
      function compareVornameDESC(a, b) {
        if (a.person.vorname > b.person.vorname) {
          return -1
        } else {
          return 1
        }
      }
      function compareDateTimeASC(a, b) {
        if (a.beginn.datum > b.beginn.datum) {
          return 1
        } else if (a.beginn.datum == b.beginn.datum) {
          if (a.beginn.uhrzeit > b.beginn.uhrzeit) {
            return 1
          } else {
            return -1
          }
        } else {
          return -1
        }
      }

      if (sort == 'ID' && asc == 'ASC') {
        this.team_members.sort(compareStartnrASC)
      }
      if (sort == 'ID' && asc == 'DESC') {
        this.team_members.sort(compareStartnrDESC)
      }
      if (sort == 'Name' && asc == 'ASC') {
        this.team_members.sort(compareNameASC)
      }
      if (sort == 'Name' && asc == 'DESC') {
        this.team_members.sort(compareNameDESC)
      }
      if (sort == 'Vorname' && asc == 'ASC') {
        this.team_members.sort(compareVornameASC)
      }
      if (sort == 'Vorname' && asc == 'DESC') {
        this.team_members.sort(compareVornameDESC)
      }
      if (sort == 'DateTime' && asc == 'ASC') {
        this.disziplinen.sort(compareDateTimeASC)
      }
    },
    maxzuschauer_erreicht() {
      if (this.sportstaette.maxzuschauer) {
        if (
          this.get_derzeit_anwesende_personen() > this.sportstaette.maxzuschauer
        ) {
          return true
        } else {
          return false
        }
      } else {
        if (
          this.get_derzeit_anwesende_personen() > this.user.data.maxzuschauer
        ) {
          return true
        } else {
          return false
        }
      }
    },
    search_athletes() {
      if (this.db_search) {
        this.athletes_results = []
        var divided_name = this.db_search.split(' ')
        this.athletes.forEach((member) => {
          if (
            member.person.vorname.includes(this.db_search) ||
            member.person.name.includes(this.db_search)
          ) {
            this.athletes_results.push(member)
          } else if (divided_name.length > 1) {
            if (
              member.person.vorname.includes(divided_name[0]) &&
              member.person.name.includes(divided_name[1])
            ) {
              this.athletes_results.push(member)
            }
          } else if (member.person.startnummer) {
            if (member.startnummer.includes(this.db_search)) {
              this.athletes_results.push(member)
            }
          }
        })
      } else {
        this.athletes_results = this.athletes
      }
    },
    addNull(num) {
      if (num >= 10) {
        return num
      } else {
        return '0' + num
      }
    },
    calc_ende() {
      var datum = new Date()
      datum.setHours(datum.getHours() + parseInt(this.zeitwahl.hours))
      datum.setMinutes(datum.getMinutes() + parseInt(this.zeitwahl.minutes))
      return new Zeit('', '', datum.getTime())
    },
    add_athletes_disziplin() {
      var veranstaltung = this.veranstaltung.data
      veranstaltung.id = this.veranstaltung.id
      var endzeit = new Zeit(
        this.zs.disziplin.ende.datum,
        this.zs.disziplin.ende.uhrzeit,
      )

      var zutritt = new Zeit(this.timer.datum, this.timer.uhrzeit)
      for (let i = 0; i < this.selected_athletes.length; i++) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Anwesenheit')
          .add({
            person: this.selected_athletes[i].person,
            disziplin: this.zs.disziplin,
            zutritt: zutritt.toJSON(),
            verlassen: endzeit.toJSON(),
            sportstaette: this.sportstaette,
            veranstaltung: veranstaltung,
            aktivitaet: this.selected_athletes[i].person.aktivitaet,
            ggg: this.testpflicht_required
              ? this.selected_athletes[i].geimpft
                ? {
                    getestet: false,
                    geimpft: true,
                    genesen: false,
                  }
                : this.selected_athletes[i].genesen
                ? { getestet: false, geimpft: false, genesen: true }
                : this.ggg
              : '',
          })
          .then(() => {
            if (i + 1 == this.selected_athletes.length) {
              this.closing()
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    add_athletes() {
      var veranstaltung = this.veranstaltung.data
      veranstaltung.id = this.veranstaltung.id
      var endzeit = new Zeit(
        this.veranstaltung.data.ende.datum,
        this.veranstaltung.data.ende.uhrzeit,
      )

      var zutritt = new Zeit(this.timer.datum, this.timer.uhrzeit)
      for (let i = 0; i < this.selected_athletes.length; i++) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Anwesenheit')
          .add({
            person: this.selected_athletes[i].person,
            zutritt: zutritt.toJSON(),
            verlassen: endzeit.toJSON(),
            sportstaette: this.sportstaette,
            veranstaltung: veranstaltung,
            aktivitaet: this.selected_athletes[i].person.aktivitaet,
            ggg: this.testpflicht_required
              ? this.selected_athletes[i].geimpft
                ? {
                    getestet: false,
                    geimpft: true,
                    genesen: false,
                  }
                : this.selected_athletes[i].genesen
                ? { getestet: false, geimpft: false, genesen: true }
                : this.ggg
              : '',
          })
          .then((docRef) => {
            if (i + 1 == this.selected_athletes.length) {
              this.closing()
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    add_vereinsmember() {
      var veranstaltung = {
        id: 'ANDERE',
        art: 'Andere',
        name: 'Andere Veranstaltung',
      }
      var endzeit = {
        datum: '',
        uhrzeit: '',
      }
      if (this.zs.veranstaltung.id != 'ANDERE') {
        veranstaltung = this.zs.veranstaltung
        endzeit = new Zeit(
          this.zs.veranstaltung.ende.datum,
          this.zs.veranstaltung.ende.uhrzeit,
        )
      } else {
        endzeit = this.calc_ende()
      }
      var zutritt = new Zeit()
      for (let i = 0; i < this.selected_members.length; i++) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Anwesenheit')
          .add({
            person: {
              vorname: this.selected_members[i].vorname.trim(),
              name: this.selected_members[i].name.trim(),
              adresse: this.selected_members[i].adresse.trim(),
              plz: this.selected_members[i].plz.trim(),
              ort: this.selected_members[i].ort.trim(),
              telefon: this.selected_members[i].telefon.trim(),
            },
            ggg: this.testpflicht_required
              ? this.selected_members[i].geimpft
                ? {
                    getestet: false,
                    geimpft: true,
                    genesen: false,
                  }
                : this.selected_members[i].genesen
                ? { getestet: false, geimpft: false, genesen: true }
                : this.ggg
              : '',
            zutritt: zutritt.toJSON(),
            weitere_personen: this.zs.weitere_personen,
            verlassen: endzeit.toJSON(),
            sportstaette: this.sportstaette,
            veranstaltung: veranstaltung,
            aktivitaet: this.selected_members[i].aktivitaet
              ? this.selected_members[i].aktivitaet
              : 'Zuschauer',
          })
          .then(() => {
            if (i + 1 == this.selected_members.length) {
              this.closing()
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    select_member(index) {
      this.team_members[index].selected
        ? (this.team_members[index].selected = false)
        : (this.team_members[index].selected = true)
      var new_team_members = this.team_members
      this.team_members = []
      this.selected_members = []
      for (let i = 0; i < new_team_members.length; i++) {
        this.team_members.push(new_team_members[i])
        if (new_team_members[i].selected) {
          this.selected_members.push(new_team_members[i])
        }
      }
    },
    select_team(team) {
      if (team != 'DB') {
        this.teamloading = team
        this.teamselection = team
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Team')
          .doc(team)
          .collection('Mitglieder')
          .get()
          .then((snap) => {
            this.team_members = []
            snap.forEach((doc) => {
              this.team_members.push(doc.data())
              var index = this.team_members.length - 1
              this.team_members[index].id = doc.id
              this.team_members[index].team = team
              if (this.anwesende_member.includes(team + '-' + doc.id)) {
                this.team_members[index].anwesend = true
              }
            })
            this.teamloading = false
          })
      } else {
        this.teamloading = team
        this.teamselection = team
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Personen')
          .get()
          .then((querySnap) => {
            this.team_members = []
            querySnap.forEach((doc) => {
              this.team_members.push(doc.data())
              var index = this.team_members.length - 1
              this.team_members[index].id = doc.id
              this.team_members[index].team = team
              if (this.anwesende_member.includes(team + '-' + doc.id)) {
                this.team_members[index].anwesend = true
              }
            })
            this.teamloading = false
          })
      }
    },
    select_athlete(index) {
      this.athletes_results[index].selected
        ? (this.athletes_results[index].selected = false)
        : (this.athletes_results[index].selected = true)
      var new_athletes = this.athletes_results
      this.athletes_results = []
      this.selected_athletes = []
      for (let i = 0; i < new_athletes.length; i++) {
        this.athletes_results.push(new_athletes[i])
        if (new_athletes[i].selected) {
          this.selected_athletes.push(new_athletes[i])
        }
      }
    },
    select_disziplin(disziplin) {
      if (disziplin.id != 'POOL') {
        this.zs.disziplin = disziplin
        this.disziplinloading = disziplin.id

        var athleten = []
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Veranstaltung')
          .doc(this.veranstaltung.id)
          .collection('Anmeldung')
          .where('disziplinen_ids', 'array-contains', disziplin.id)
          .orderBy('startnummer')
          .orderBy('person.name')
          .get()
          .then((querySnap) => {
            athleten = []
            querySnap.forEach((doc) => {
              athleten.push(doc.data())
              var index = athleten.length - 1
              athleten[index].id = doc.id
              athleten[index].person.id = doc.id
            })
          })
          .then(() => {
            this.athletes = []
            var anwesend = []
            var liste = this.veranstaltung.anwesenheit
            for (let i = 0; i < liste.length; i++) {
              if (liste[i].disziplin) {
                if (liste[i].disziplin.id == disziplin.id) {
                  anwesend.push(liste[i].person.id)
                }
              }
            }
            for (let i = 0; i < athleten.length; i++) {
              if (!anwesend.includes(athleten[i].id)) {
                this.athletes.push(athleten[i])
              }
            }
            this.disziplinloading = ''
            this.disziplinselection = disziplin.id
          })
      } else {
        this.teamloading = team
        this.teamselection = team
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Personen')
          .get()
          .then((querySnap) => {
            this.team_members = []
            querySnap.forEach((doc) => {
              this.team_members.push(doc.data())
              var index = this.team_members.length - 1
              this.team_members[index].id = doc.id
            })
            this.teamloading = false
          })
      }
    },
    check_current_time() {
      if (this.timer.sekunden == '00') {
        this.check_current_time_once()
        setTimeout(() => {
          this.check_current_time()
        }, 60000)
      } else {
        setTimeout(() => {
          this.check_current_time()
        }, 500)
      }
    },
    check_current_time_once() {
      this.aktualisiere_personenzahl = true
      this.aktualisiere_veranstaltungen = true
      var athleten = []
      this.athleten_ids = []
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .collection('Disziplin')
        .where('beginn.datum', '==', this.timer.datum)
        .get()
        .then((snap) => {
          this.disziplinen = []
          var i = 0
          snap.forEach((doc) => {
            var d = doc.data()
            this.disziplinen.push(doc.data())
            this.disziplinen[i].id = doc.id
            i++
          })
          this.sortieren('DateTime', 'ASC')
          this.aktualisiere_veranstaltungen = false
        })
        .then(() => {
          firebase
            .firestore()
            .collection('User')
            .doc(this.user.data.uid)
            .collection('Veranstaltung')
            .doc(this.veranstaltung.id)
            .collection('Anmeldung')
            .orderBy('person.name')
            .get()
            .then((querySnap) => {
              athleten = []
              querySnap.forEach((doc) => {
                athleten.push(doc.data())
                var index = athleten.length - 1
                athleten[index].id = doc.id
                athleten[index].person.id = doc.id
              })
            })
            .then(() => {
              firebase
                .firestore()
                .collection('User')
                .doc(this.user.data.uid)
                .collection('Anwesenheit')
                .where('verlassen.timestamp', '>', Date.now())
                .get()
                .then((querySnap) => {
                  this.anwesende_personen = []
                  this.anwesende_member = []
                  querySnap.forEach((doc) => {
                    var person = doc.data()
                    person.id = doc.id
                    if (!person.veranstaltung) {
                      person.veranstaltung = {
                        id: 'ANDERE',
                        art: 'Andere',
                      }
                    }
                    if (
                      doc.data().zutritt.datum <= this.timer.datum &&
                      doc.data().zutritt.uhrzeit <= this.timer.uhrzeit &&
                      doc.data().verlassen.datum >= this.timer.datum &&
                      doc.data().verlassen.uhrzeit >= this.timer.uhrzeit &&
                      (person.sportstaette.name == this.sportstaette.name ||
                        (this.sportstaette.main && !person.sportstaette))
                    ) {
                      var add = true
                      if (person.veranstaltung) {
                        if (person.veranstaltung.art == 'Auswärtsspiel') {
                          add = false
                        }
                      }
                      if (add) {
                        this.anwesende_personen.push(person)
                        if (
                          doc.data().person
                            ? doc.data().person.id
                              ? true
                              : false
                            : false
                        ) {
                          this.athleten_ids.push(doc.data().person.id)
                        }
                        if (doc.data().weitere_personen) {
                          var wp = doc.data().weitere_personen
                          wp.forEach((p) => {
                            var daten = doc.data()
                            if (p.vorname) {
                              daten.person.vorname = p.vorname
                              daten.person.name = p.nachname
                            } else {
                              var name = p.split(' ')
                              daten.person.vorname = name[0]
                              daten.person.name = name[1]
                            }

                            daten.id = doc.id
                            this.anwesende_personen.push(daten)
                          })
                        }
                      }
                    }
                  })
                })
                .then(() => {
                  this.athletes = []
                  var anwesend = []
                  var liste = this.veranstaltung.anwesenheit
                  for (let i = 0; i < liste.length; i++) {
                    if (liste[i].disziplin) {
                      if (liste[i].disziplin.id == disziplin.id) {
                        anwesend.push(liste[i].person.id)
                      }
                    }
                  }
                  for (let i = 0; i < athleten.length; i++) {
                    if (!anwesend.includes(athleten[i].id)) {
                      this.athletes.push(athleten[i])
                    }
                  }
                  this.search_athletes()
                })
            })
        })
        .then(() => {
          setTimeout(() => {
            this.aktualisiere_personenzahl = false
          }, 1500)
        })
    },
    parseDate(date) {
      var datum = date.split('-')
      var datum_obj = new Date(date)
      var weekdays = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
      var weekday = datum_obj.getDay()
      return (
        weekdays[weekday] + ', ' + datum[2] + '.' + datum[1] + '.' + datum[0]
      )
    },
    get_derzeit_anwesende_personen() {
      return this.anwesende_personen.length
    },
    async onDetect(promise) {
      try {
        const {
          imageData, // raw image data of image/frame
          content, // decoded String or null
          location, // QR code coordinates or null
        } = await promise

        if (content === null) {
          // decoded nothing
        } else {
          var daten = JSON.parse(content)
          if (daten.verein && daten.person) {
            firebase
              .firestore()
              .collection('User')
              .doc(daten.verein)
              .collection('Personen')
              .doc(daten.person)
              .get()
              .then((result) => {
                var person = result.data()
                this.zs.vorname = person.vorname
                this.zs.name = person.name
                this.zs.adresse = person.adresse
                this.zs.plz = person.plz
                this.zs.ort = person.ort
                this.zs.telefon = person.telefon
                this.qrcodedata = true
              })
          } else {
            this.qrcodedata = false
          }
        }
      } catch (error) {
        // ...
        console.log(error)
      }
    },
    async onInit(promise) {
      try {
        this.camera_loading = true
        await promise
        this.camera_loading = false
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson'
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device'
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)'
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser'
        }
        this.camera_loading = false
      }
    },
    closing() {
      this.zs = {
        veranstaltung: '',
        disziplin: '',
        type: '',
        person: {
          name: '',
          vorname: '',
          adresse: '',
          plz: '',
          ort: '',
          telefon: '',
          nation: '',
          email: '',
          aktivitaet: '',
        },
        save_person: false,
      }
      this.duplicate_zuschauer = {
        active: false,
        vorname: '',
        name: '',
      }
      this.newzuschauer = false
      this.teamselection = ''
      this.team_members = []
      this.selected_members = []
      this.selected_athletes = []
      this.disziplinselection = ''
    },
    search_person() {
      var searchresults = []
      for (let i = 0; i < this.anwesende_personen.length; i++) {
        if (
          this.anwesende_personen[i].person.vorname.includes(
            this.zs.person.vorname,
          ) &&
          this.anwesende_personen[i].person.name.includes(this.zs.person.name)
        ) {
          searchresults.push(this.anwesende_personen[i])
        }
      }
      if (searchresults.length > 0) {
        this.duplicate_zuschauer = {
          active: true,
          vorname: this.zs.person.vorname,
          name: this.zs.person.name,
        }
        return true
      } else {
        return false
      }
    },
    zuschauer_erfassen() {
      if (
        this.search_person() &&
        this.duplicate_zuschauer.active &&
        !this.accept_duplicate_zuschauer
      ) {
        return
      }
      if (this.zs.type == 'person') {
        if (this.zs.save_person == true) {
          firebase
            .firestore()
            .collection('User')
            .doc(this.user.data.uid)
            .collection('Personen')
            .add({
              adresse: this.zs.adresse.trim(),
              name: this.zs.name.trim(),
              vorname: this.zs.vorname.trim(),
              plz: this.zs.plz.trim(),
              ort: this.zs.ort.trim(),
              telefon: this.zs.telefon.trim(),
              aktivitaet: this.zs.aktivitaet.trim(),
            })
        }
      }
      var veranstaltung = this.veranstaltung.data
      veranstaltung.id = this.veranstaltung.id
      var endzeit = this.calc_ende()
      var zutritt = new Zeit()
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .add({
          person: this.zs.person,
          zutritt: zutritt.toJSON(),
          verlassen: endzeit.toJSON(),
          sportstaette: this.sportstaette,
          veranstaltung: veranstaltung,
          aktivitaet: this.zs.person.aktivitaet,
          ggg: this.testpflicht_required ? this.ggg : '',
        })
        .then(() => {
          this.closing()
        })
    },
  },
}
</script>
